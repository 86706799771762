import React, { useState, useEffect, useRef } from 'react';
import './startDate.css';

const Dropdown = ({ options, value, onChange, className, onOpen, isOpenFromParent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      onOpen(null);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenFromParent === className);
  }, [isOpenFromParent, className]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    if (!isOpen) {
      setIsOpen(true);
      onOpen(className);
    } else {
      setIsOpen(false);
      onOpen(null);
    }
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
    onOpen(null);
  };

  return (
    <div className={`dropdown ${className}`} ref={dropdownRef}>
      <div className="dropdown-content">
        <div className="selected" onClick={handleDropdownToggle}>
          {value}
        </div>
        {isOpen && (
          <div className="options">
            {options.map(option => (
              <div
                key={option}
                onMouseDown={() => handleOptionClick(option)}
                onClick={() => handleOptionClick(option)}
                className={option === value ? 'option selected' : 'option'}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const generateOptions = (start, end, isAscending = true) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(i);
  }
  return isAscending ? options : options.reverse();
};

const StartDate = ({ onStartDateChange, endDate, onOpen, isOpenFromParent }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(1); 
  const [day, setDay] = useState(1);
  const [startDate, setStartDate] = useState(new Date(year, month - 1, day)); 

  useEffect(() => {
    setYear(endDate.getFullYear() - 4);
    setMonth(endDate.getMonth() + 1);
    setDay(endDate.getDate());
  }, []);

  useEffect(() => {
    const newDate = new Date(year, month - 1, day);
    if (newDate > endDate) {
      setYear(endDate.getFullYear());
      setMonth(endDate.getMonth() + 1);
      setDay(endDate.getDate());
    }
    setStartDate(newDate);
  }, [day, month, year, endDate]);

  useEffect(() => {
    onStartDateChange(startDate); 
  }, [startDate, onStartDateChange]); 

  const years = generateOptions(2013, endDate.getFullYear(), false);
  const months = year === endDate.getFullYear() ? generateOptions(1, endDate.getMonth() + 1) : generateOptions(1, 12);
  let days = (year === endDate.getFullYear() && month === endDate.getMonth() + 1) ? generateOptions(1, endDate.getDate()) : generateOptions(1, new Date(year, month, 0).getDate());

  if (year === endDate.getFullYear()) {
    if (month > endDate.getMonth() + 1) {
      setMonth(endDate.getMonth() + 1);
    } else if (month === endDate.getMonth() + 1 && day > endDate.getDate()) {
      setDay(endDate.getDate());
    }
  }

  return (
    <div className="childStyle">
      <div className="dateSelectsDiv">
        <Dropdown className="dateSelected firstDropdown" 
            options={days} 
            value={day.toString().padStart(2, '0')} 
            onChange={(value) => {
              const newDay = parseInt(value);
              setDay(newDay);
            }}
            onOpen={onOpen}
            isOpenFromParent={isOpenFromParent}
          />
          <span className="date-hyphen">/</span>
          <Dropdown className="dateSelected" 
            options={months} 
            value={month.toString().padStart(2, '0')} 
            onChange={(value) => {
              const newMonth = parseInt(value);
              setMonth(newMonth);
            }} 
            onOpen={onOpen}
            isOpenFromParent={isOpenFromParent}
          />
          <span className="date-hyphen">/</span>
          <Dropdown className="dateSelected" 
            options={years} 
            value={year.toString().padStart(2, '0')} 
            onChange={(value) => {
              const newYear = parseInt(value);
              if (newYear === endDate.getFullYear() && (month > endDate.getMonth() + 1 || day > endDate.getDate())) {
                setYear(endDate.getFullYear());
                setMonth(endDate.getMonth() + 1);
                setDay(endDate.getDate());
              } else {
                setYear(newYear);
              }
            }} 
            onOpen={onOpen}
            isOpenFromParent={isOpenFromParent}
          />
      </div>
      <div className="SettingsGrayBorder">Start</div>
    </div>
  );
};

export default StartDate;