import React, { useEffect, useState } from "react";
import { getCurrentPrice } from "../../../BitcoinPriceAPI/BTCPriceAPI";

const PurchasingPower = ({
  bitcoinAccumulated,
  setPurchasingPower,
  currencyCode,
}) => {
  const [bitcoinValue, setBitcoinValue] = useState(0);

  useEffect(() => {
    const getBitcoinValue = async () => {
      try {
        // console.log('bitcoinAccumulated:', bitcoinAccumulated); 
        const currentPrice = await getCurrentPrice(currencyCode); 
        // console.log('currentPrice:', currentPrice); 
        const value = bitcoinAccumulated * currentPrice;
        // console.log('value:', value); 
        setBitcoinValue(value);
        setPurchasingPower(value); 
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getBitcoinValue();
  }, [bitcoinAccumulated, setPurchasingPower, currencyCode]);

  let formattedBitcoinUSDValue;
  if (bitcoinValue < 1_000_000) {
    formattedBitcoinUSDValue = Math.round(bitcoinValue).toLocaleString();
  } else {
    formattedBitcoinUSDValue = (bitcoinValue / 1_000_000).toFixed(2) + 'M';
  }

  return (
    <>
      <div
        style={{
          color: "white",
          fontFamily: "IBM Plex Sans",
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "40px",
          padding: "0px",
          margin: "0px",
        }}
      >
        ${formattedBitcoinUSDValue}
      </div>
    </>
  );
};

export default PurchasingPower;