import React, { useState, useRef } from 'react';
import CurrencySelection from "../currencySelection/currencySelection";
import "./amount.css";

const Amount = ({ initialAmount, onAmountChange, currencyCode, setCurrencyCode }) => {
  const [amount, setAmount] = useState(initialAmount);
  const [isEditing, setIsEditing] = useState(false);
  const timerIdRef = useRef(null);

  const handleAmountChange = (event) => {
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 1000000) {
      setAmount(newValue);
      setIsEditing(true);
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
      timerIdRef.current = setTimeout(() => {
        onAmountChange(newValue);
      }, 2500);
    } else {
      setIsEditing(false);
    }
  };

  const handleAmountFocus = () => {
    setIsEditing(true);
  };

  const [openDropdown, setOpenDropdown] = useState(null); 

  const customStyles = { 
      color: "var(--Content-content1, #F2F2F7)", 
      background: "none", 
      border: "none", 
      outline: "none",
      borderRadius: "25px",
      fontFamily: 'Inter',
      fontSize: window.innerWidth <= 768 ? "20px" : "22px",
      fontWeight: '600',
      lineHeight: '32px',
      letterSpacing: '-0.22px',
  };

  const subtitleStyles = {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    color: "var(--Content-content1, #F2F2F7)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    marginLeft: '0px',
    paddingLeft: '0px',
  };

  const dollarAndInput = {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className="childStyle">
      <div style={dollarAndInput}>
      <span style={subtitleStyles}>$</span>
      <input
        type="text"
        placeholder={isEditing ? "" : "21"}
        value={isEditing ? amount : ""}
        onChange={handleAmountChange}
        onFocus={handleAmountFocus}
        style={{...customStyles, width: '90px', height: '32px',}}
      />
      </div>
      <CurrencySelection 
        initialCurrencyCode={currencyCode} 
        onCurrencyCodeChange={setCurrencyCode} 
        open={openDropdown === 'currency'} 
        onOpen={() => setOpenDropdown('currency')}
      />
    </div>
  );
};

export default Amount;