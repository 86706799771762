import React, { useState, useRef } from 'react';
import "./App.css";
import Settings from "./components/settings/Settings";
import Results from "./components/settings/results/Result";

const options = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

function App() {
  const [amount, setAmount] = useState(21);
  const [frequency, setFrequency] = useState(options[0].value); 
  const [endDate, setEndDate] = useState(new Date()); 
  const [startDate, setStartDate] = useState(new Date(endDate.getFullYear() - 4, endDate.getMonth(), endDate.getDate())); 
  const [fiatExchanged, setFiatExchanged] = useState(0);
  const [currencyCode, setCurrencyCode] = useState('aud');
  const [openDropdown, setOpenDropdown] = useState(null); 
  const currencyDropdownRef = useRef(null);

  return (
    <div className="App">
      <h2 className="CalculatorTitle">Bitcoin DCA Calculator</h2>
      <h3 className="CalculatorSubtitle">Dollar Cost Averaging</h3>
      <Settings 
        amount={amount} 
        setAmount={setAmount}
        frequency={frequency} 
        setFrequency={setFrequency}
        startDate={startDate} 
        setStartDate={setStartDate}
        endDate={endDate} 
        setEndDate={setEndDate}
        openDropdown={openDropdown} 
        onOpen={(dropdownName) => setOpenDropdown(dropdownName)} 
        currencyDropdownRef={currencyDropdownRef}
        currencyCode={currencyCode}
        setCurrencyCode={setCurrencyCode}
      />
      <div style={{ marginBottom: '32px' }}></div>
      <Results 
        amount={amount} 
        frequency={frequency} 
        startDate={startDate} 
        endDate={endDate} 
        fiatExchanged={fiatExchanged}
        setFiatExchanged={setFiatExchanged} 
        currencyCode={currencyCode} 
      />
    </div>
  );
}

export default App;
