import React, { useState } from "react";
import Amount from "../settings/amount/Amount";
import Frequency from "../settings/frequency/Frequency";
import StartDate from "../settings/startDate/startDate";
import EndDate from "../settings/endDate/endDate";
import CurrencySelection from "../settings/currencySelection/currencySelection";
import "./settings.css";

const options = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1C1C1E",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FF8700",
  }),
  option: (provided) => ({
    ...provided,
    color: "#FF8700",
  }),
};

const Settings = ({
  amount,
  setAmount,
  frequency,
  setFrequency,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  currencyDropdownRef,
  currencyCode,
  setCurrencyCode,
}) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleOpenDropdown = (dropdownName) => {
    setOpenDropdown(prevDropdown => prevDropdown === dropdownName ? null : dropdownName);
  };

  return (
    <div className="SettingsComponent">
      <div className="settingsDivMain">
        <div className="FirstTwoSettings">
        <Amount
          initialAmount={amount}
          onAmountChange={setAmount}
          onOpen={() => handleOpenDropdown("amount")}
          openDropdown={openDropdown}
          currencyCode={currencyCode}
          setCurrencyCode={setCurrencyCode}
        />
        <Frequency
          initialFrequency={frequency}
          options={options}
          customStyles={customStyles}
          onFrequencyChange={setFrequency}
          onOpen={() => handleOpenDropdown("frequency")}
          openDropdown={openDropdown}
          otherDropdownRef={currencyDropdownRef}
        />
        </div>
        <div className="LastTwoSettings">
          <StartDate
          initialStartDate={startDate}
          onStartDateChange={setStartDate}
          endDate={endDate}
          onOpen={() => handleOpenDropdown("startDate")}
          openDropdown={openDropdown}
        />
        <EndDate
          initialEndDate={endDate}
          onEndDateChange={setEndDate}
          startDate={startDate}
          onOpen={() => handleOpenDropdown("endDate")}
          openDropdown={openDropdown}
        />
        </div>
      </div>
    </div>
  );
};

export default Settings;
