import axios from "axios";

const API_KEY = "CG-tu4w8kRMqEqdtnGdDcdxNacV";

export const getCurrentPrice = async (currency) => {
  try {
    const response = await axios.get(`https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=bitcoin&x_cg_pro_api_key=${API_KEY}`);
    // console.log('API response:', response.data);
    const marketData = response.data[0];
    return Number(marketData.current_price);
  } catch (error) {
    console.error(error);
  }
};

export const getHistoricalPrice = async (startDate, endDate, currency) => {
  const start = Math.floor(new Date(startDate).getTime() / 1000);
  const end = Math.floor(new Date(endDate).getTime() / 1000);
  const coinGeckoUrl = `https://pro-api.coingecko.com/api/v3/coins/bitcoin/market_chart/range?vs_currency=${currency}&from=${start}&to=${end}&x_cg_pro_api_key=${API_KEY}`;

  const response = await axios.get(coinGeckoUrl);

  const prices = response.data.prices;

  // Filter prices to get only the first price of each day
  let lastDate = null;
  const dailyPrices = prices.filter(([timestamp]) => {
    const date = new Date(timestamp).toDateString();
    if (date !== lastDate) {
      lastDate = date;
      return true;
    }
    return false;
  });

  return dailyPrices;
};