import React, { useEffect, useState } from 'react';
import { getHistoricalPrice } from '../../../BitcoinPriceAPI/BTCPriceAPI';

const BitcoinAccumulated = ({ amount, frequency, startDate, endDate, setBitcoinAccumulated: setBitcoinAccumulatedProp, currencyCode }) => {
    const [bitcoinAccumulated, setBitcoinAccumulated] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const calculateResults = async () => {
            setLoading(true);
            const prices = await getHistoricalPrice(startDate, endDate, currencyCode);

            let totalBitcoin = 0;
            let nextPurchaseDate = new Date(startDate);

            for (let i = 0; i < prices.length; i++) {
                const priceDate = new Date(prices[i][0]);
                const price = prices[i][1];

                if (priceDate >= nextPurchaseDate) {
                    const bitcoin = amount / price;
                    totalBitcoin += bitcoin;

                    switch (frequency) {
                        case 'daily':
                            nextPurchaseDate.setDate(nextPurchaseDate.getDate() + 1);
                            break;
                        case 'weekly':
                            nextPurchaseDate.setDate(nextPurchaseDate.getDate() + 7);
                            break;
                        case 'monthly':
                            nextPurchaseDate.setMonth(nextPurchaseDate.getMonth() + 1);
                            break;
                        case 'yearly':
                            nextPurchaseDate.setFullYear(nextPurchaseDate.getFullYear() + 1);
                            break;
                        default:
                            nextPurchaseDate.setDate(nextPurchaseDate.getDate() + 1);
                    }
                }
            }

            setBitcoinAccumulated(totalBitcoin);
            setBitcoinAccumulatedProp(totalBitcoin);
            setLoading(false);
        };

        const timeoutId = setTimeout(calculateResults, 500); 

        return () => clearTimeout(timeoutId); 
    }, [amount, frequency, startDate, endDate, setBitcoinAccumulatedProp, currencyCode]);

    if (loading) {
        return "";
    }

    return (
        <div className="BitcoinAccumulated">
            <p style={{
            color: '#FF8700',
            fontFamily: 'IBM Plex Sans',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '40px',
            padding: '0px',
            margin: '0px',
        }}>{bitcoinAccumulated > 10000 ? bitcoinAccumulated.toFixed(3) : bitcoinAccumulated > 1000 ? bitcoinAccumulated.toFixed(4) : bitcoinAccumulated > 100 ? bitcoinAccumulated.toFixed(6) : bitcoinAccumulated.toFixed(8)} ₿</p>
        </div>
    );
};

export default BitcoinAccumulated;