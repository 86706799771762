import React, { useEffect, useState } from 'react';

const SavingsGrowth = ({ purchasingPower, totalFiatExchanged, formattedTotalFiatExchanged }) => {
    const [percentage, setPercentage] = useState('N/A');

    useEffect(() => {
        // console.log('purchasingPower after update:', purchasingPower);
        // console.log('purchasingPower:', purchasingPower); // Log the value of purchasingPower
        // console.log('totalFiatExchanged:', totalFiatExchanged); // Log the value of totalFiatExchanged

        const calculatePercentage = () => {
            if (purchasingPower === 0 && totalFiatExchanged === 0) {
                setPercentage('0%');
                return;
              }
            if (purchasingPower !== 0 && !isNaN(purchasingPower) && !isNaN(totalFiatExchanged) && totalFiatExchanged !== 0) {
                let newPercentage = ((purchasingPower - totalFiatExchanged) / totalFiatExchanged) * 100;
                // console.log('Calculated percentage:', newPercentage); // Log the calculated percentage
                newPercentage = `${newPercentage >= 0 ? '+' : ''}${newPercentage.toFixed(2)}%`;
                if (newPercentage !== percentage) {
                    setPercentage(newPercentage);
                }
            } else {
                setPercentage('0%');
                // console.log('Invalid values for calculation'); // Log a message if the values are not valid for calculation
            }
        };
        calculatePercentage();
    }, [purchasingPower, totalFiatExchanged, percentage]);

    return (
        <div>
            <div style={{
            color: '#FF8700',
            fontFamily: 'IBM Plex Sans',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '40px',
            padding: '0px',
            margin: '0px',
        }}>{percentage}</div>
        </div>
    );
};

export default SavingsGrowth;