import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./currencyselect.css";
import flagAUD from "../../assets/flagAUD.png";
import flagCAD from "../../assets/flagCAD.png";
import flagNZD from "../../assets/flagNZD.png";
import flagUSD from "../../assets/flagUSD.png";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0, 
  }),
  singleValue: (provided, state) => ({
    ...provided,
    display: "flex",
    padding: "4px 8px",
    justifyContent: "center",
    alignItems: "center",
    color: state.isFocused
      ? "var(--utility-input-2, rgba(142, 142, 147, 0.24))"
      : "white",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "-0.064px",
    fontFeatureSettings: "'case' on",
    borderRadius: "32px",
    border: "1px solid #FFF",
    marginRight: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "transparent !important",
  }),
  indicatorSeparator: () => null,
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1C1C1E",
    borderRadius: "15px",
    
    border: "none",
    width: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: "#1C1C1E",
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
    outline: "none",
    boxShadow: "none",
    cursor: "pointer",
  }),
};

const options = [
  { value: "aud", flag: flagAUD },
  { value: "cad", flag: flagCAD },
  { value: "nzd", flag: flagNZD },
  { value: "usd", flag: flagUSD },
];

const CurrencySelection = ({ initialCurrencyCode, onCurrencyCodeChange, onOpen }) => {
    const [currencyCode, setCurrencyCode] = useState(
      options.find((option) => option.value === initialCurrencyCode)
    );
    const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    const handleCurrencyCodeChange = (selectedOption) => {
      // console.log('selectedOption:', selectedOption);
      setCurrencyCode(selectedOption);
      setIsCurrencyOpen(false);
      onCurrencyCodeChange(selectedOption.value);
    };
  
    useEffect(() => {
      onOpen(isCurrencyOpen ? 'currency' : null);
    }, [isCurrencyOpen, onOpen]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsCurrencyOpen(false);
          onOpen(null);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onOpen]);

    return (
      <div className="currencySelectionStyle" ref={dropdownRef}>
        <div className="SettingsDropDown">
          <Select
            className="currencySelect"
            classNamePrefix="select"
            isSearchable={false}
            menuIsOpen={isCurrencyOpen}
            value={options.find((option) => option.value === initialCurrencyCode)}
            onChange={handleCurrencyCodeChange}
            options={options}
            styles={customStyles}
            onMenuOpen={() => {
              setIsCurrencyOpen(true);
              onOpen('currency');
            }}
            onMenuClose={() => {
              setIsCurrencyOpen(false);
              onOpen(null);
            }}
            components={{
              DropdownIndicator: () => null, 
            }}
            formatOptionLabel={({ value, label, flag }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "8px" }}>{value.toUpperCase()}</span>
                <img src={flag} alt={label} style={{ width: '16px', height: '16px' }} />
                {label}
              </div>
            )}
          />
        </div>
      </div>
    );
  };
  
  export default CurrencySelection;