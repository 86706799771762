import React, { useReducer, useState, useEffect, useRef } from 'react';
import './endDate.css';

const Dropdown = ({ options, value, onChange, className, onOpen, isOpenFromParent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      onOpen(null);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenFromParent === className);
  }, [isOpenFromParent, className]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    if (!isOpen) {
      setIsOpen(true);
      onOpen(className);
    } else {
      setIsOpen(false);
      onOpen(null);
    }
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
    onOpen(null);
  };

  return (
    <div className={`dropdown ${className}`} ref={dropdownRef}>
      <div className="dropdown-content">
        <div className="selected" onClick={handleDropdownToggle}>
          {value}
        </div>
        {isOpen && (
          <div className="options">
            {options.map(option => (
              <div
                key={option}
                onMouseDown={() => handleOptionClick(option)}
                onClick={() => handleOptionClick(option)}
                className={option === value ? 'option selected' : 'option'}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const generateOptions = (start, end, isAscending = true) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(i);
  }
  return isAscending ? options : options.reverse();
};

const initialState = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

const EndDate = ({ initialEndDate, onEndDateChange, startDate }) => {
  const currentYear = new Date().getFullYear();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [endDate, setEndDate] = useState(new Date(state.year, state.month - 1, state.day));

  useEffect(() => {
    if (
      state.year < startDate.getFullYear() ||
      (state.year === startDate.getFullYear() && state.month < startDate.getMonth() + 1) ||
      (state.year === startDate.getFullYear() && state.month === startDate.getMonth() + 1 && state.day < startDate.getDate())
    ) {
      const newMonth = state.month < startDate.getMonth() + 1 ? startDate.getMonth() + 1 : state.month;
      const newDay = state.day < startDate.getDate() ? startDate.getDate() : state.day;
      dispatch({
        type: 'SET_DATE',
        payload: {
          year: startDate.getFullYear(),
          month: newMonth,
          day: newDay,
        },
      });
    }
  }, [state, startDate]);

  useEffect(() => {
    const newDate = new Date(state.year, state.month - 1, state.day);
    setEndDate(newDate);
  }, [state]);

  useEffect(() => {
    onEndDateChange(endDate); 
  }, [endDate, onEndDateChange]); 

  const years = generateOptions(startDate.getFullYear(), currentYear);
  const months = state.year === startDate.getFullYear() ? generateOptions(startDate.getMonth() + 1, 12) : generateOptions(1, 12);
  const days = (state.year === startDate.getFullYear() && state.month === startDate.getMonth() + 1) ? generateOptions(startDate.getDate(), new Date(state.year, state.month, 0).getDate()) : generateOptions(1, new Date(state.year, state.month, 0).getDate());

  return (
    <div className="childStyle">
      <div className="dateSelectsDiv">
      <Dropdown className="dateSelected firstDropdown" 
        options={days} 
        value={state.day.toString().padStart(2, '0')} 
        onChange={(value) => {
          const newDay = parseInt(value);
          if (state.year === startDate.getFullYear() && state.month === startDate.getMonth() + 1 && newDay < startDate.getDate()) {
            dispatch({ type: 'SET_DATE', payload: { day: startDate.getDate() } });
          } else {
            dispatch({ type: 'SET_DATE', payload: { day: newDay } });
          }
        }}
        onOpen={() => {}}
    />
      <span className="date-hyphen">/</span>
      <Dropdown className="dateSelected" 
          options={months} 
          value={state.month.toString().padStart(2, '0')} 
          onChange={(value) => {
            const newMonth = parseInt(value);
            if (state.year === startDate.getFullYear() && newMonth < startDate.getMonth() + 1) {
              dispatch({ type: 'SET_DATE', payload: { month: startDate.getMonth() + 1 } });
            } else {
              dispatch({ type: 'SET_DATE', payload: { month: newMonth } });
            }
          }}
          onOpen={() => {}}
      />
      <span className="date-hyphen">/</span>
      <Dropdown className="dateSelected" 
          options={years} 
          value={state.year.toString().padStart(2, '0')} 
          onChange={(value) => {
            const newYear = parseInt(value);
            if (newYear < startDate.getFullYear() || (newYear === startDate.getFullYear() && state.month < startDate.getMonth() + 1)) {
              dispatch({ type: 'SET_DATE', payload: { year: startDate.getFullYear(), month: startDate.getMonth() + 1 } });
            } else {
              dispatch({ type: 'SET_DATE', payload: { year: newYear } });
            }
          }}
          onOpen={() => {}}
      />
      </div>
      <div className="SettingsGrayBorder">End</div>
    </div>
  );
};

export default EndDate;