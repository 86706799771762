import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./frequency.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
    cursor: "pointer",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isFocused
      ? "var(--utility-input-2, rgba(142, 142, 147, 0.24))"
      : "white",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.064px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "transparent !important",
  }),
  indicatorSeparator: () => null,
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1C1C1E",
    borderRadius: "15px",
    // maxHeight: "100px",
    width: "120px",
    textAlign: "center",
    height: "150px",
    overflow: "hidden",
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "8px",
  }),
  option: (provided, state) => ({
    ...provided,
    flex: "1 0 0",
    color: "white",
    backgroundColor: "#1C1C1E",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.064px",
    outline: "none",
    boxShadow: "none",
    cursor: "pointer",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
  }),
};

const Frequency = ({
  initialFrequency,
  options,
  onFrequencyChange,
  startDate,
  endDate,
  onOpen,
}) => {
  const [frequency, setFrequency] = useState(
    options.find((option) => option.value === "daily")
  );

  const handleFrequencyChange = (selectedOption) => {
    setFrequency(selectedOption);
    onFrequencyChange(selectedOption.value, startDate, endDate);
  };

  return (
    <div className="childStyle">
      <div className="SettingsDropDown">
        <Select
          value={frequency}
          onChange={handleFrequencyChange}
          options={options}
          isSearchable={false}
          onMenuOpen={() => onOpen("frequency")}
          onMenuClose={() => onOpen(null)}
          styles={customStyles}
        />
      </div>
      <p className="SettingsGrayBorder">Frequency</p>
    </div>
  );
};

export default Frequency;
