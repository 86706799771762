import React, { useEffect, useState } from "react";
import "./results.css";
import Corn from "../../assets/Corn.png";
import BitcoinAccumulated from "./bitcoinAccumulated(BTC)/bitcoinAccumulated";
import PurchasingPower from "./purchasingPower/purchasingPower";
import SavingsGrowth from "./savingsGrowthPercentage/savingsGrowthPercentage";

const Result = ({
  amount,
  frequency,
  startDate,
  endDate,
  setFiatExchanged,
  fiatExchanged,
  currencyCode,
}) => {
  const [bitcoinAccumulated, setBitcoinAccumulated] = useState(0);
  const [purchasingPower, setPurchasingPower] = useState(0);

  const calculateTotalFiatExchanged = async (
    amount,
    frequency,
    startDate,
    endDate
  ) => {
    // console.log('calculateTotalFiatExchanged called with:', { amount, frequency, startDate, endDate });
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    
    if (!startDate || !endDate || startDate.getTime() > endDate.getTime()) {
      throw new Error("Invalid start date or end date");
    }

    let days;
    switch (frequency) {
      case "daily":
        days = 1;
        break;
      case "weekly":
        days = 7;
        break;
      case "monthly":
        days = 30;
        break;
      default:
        throw new Error("Invalid frequency: " + frequency);
    }
    const diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    const occurrences = Math.floor(diffInDays / days);
    const totalFiatExchanged = Math.round(amount * occurrences);
    return totalFiatExchanged;
  };

const [formattedTotalFiatExchanged, setFormattedTotalFiatExchanged] = useState("0.00");

useEffect(() => {
  const fetchTotalFiatExchanged = async () => {
      // console.log('fetchTotalFiatExchanged called');
      const total = await calculateTotalFiatExchanged(amount, frequency, startDate, endDate);
      // console.log('Total Fiat Exchanged:', total);
      setTotalFiatExchanged(total);
      const formattedTotalFiatExchanged = total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      setFormattedTotalFiatExchanged(formattedTotalFiatExchanged);
  };

  fetchTotalFiatExchanged();
}, [amount, frequency, startDate, endDate]);

const [totalFiatExchanged, setTotalFiatExchanged] = useState(0);

useEffect(() => {
    const fetchTotalFiatExchanged = async () => {
        // console.log('fetchTotalFiatExchanged called');
        const total = await calculateTotalFiatExchanged(amount, frequency, startDate, endDate);
        // console.log('Total Fiat Exchanged:', total);
        setTotalFiatExchanged(total);
        const formattedTotalFiatExchanged = total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        setFormattedTotalFiatExchanged(formattedTotalFiatExchanged);
    };

    fetchTotalFiatExchanged();
}, [amount, frequency, startDate, endDate]);

  return (
    <div className="ResultsMain">
      <div className="FirstTwoResults">
        <div className="ThreeBlackResults">
          <p className="ResultsTitleGray"> Fiat exchanged </p>
          <p className="ResultsNumberOrange">
            {" "}
            ${formattedTotalFiatExchanged ? formattedTotalFiatExchanged : "20"}
          </p>
        </div>
        <div className="ThreeBlackResults">
          <p className="ResultsTitleGray"> Bitcoin accumulated (BTC) </p>
          <BitcoinAccumulated
            amount={amount}
            frequency={frequency}
            startDate={startDate}
            endDate={endDate}
            setBitcoinAccumulated={setBitcoinAccumulated}
            currencyCode={currencyCode} 
          />
        </div>
      </div>
      <div className="LastTwoResults">
        <div className="ThreeBlackResults">
          <p className="ResultsTitleGray"> Savings Growth Percentage </p>
          <div className="ResultsNumberOrange">
            <SavingsGrowth
              purchasingPower={purchasingPower}
              totalFiatExchanged={totalFiatExchanged}
              formattedTotalFiatExchanged={formattedTotalFiatExchanged}
            />
          </div>
        </div>
        <div className="OneOrangeResult">
          <div className="OrangeResultInnerDiv">
            <p className="ResultsTitleOrange"> Purchasing power </p>
            <div className="ResultsNumberWhite">
              <PurchasingPower
                bitcoinAccumulated={bitcoinAccumulated}
                setPurchasingPower={setPurchasingPower}
                currencyCode={currencyCode}
              />
            </div>
          </div>
          <div>
            <img src={Corn} width={53} height={70} alt="Bitcoin Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
